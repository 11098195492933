<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>Modification d'une alerte</h1>
            <mdb-spinner v-if="isloading" />
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            <div v-if="!isloading && alertinfos">
              <mdb-row class="mb-4">
                <mdb-col>
                  <mdb-card>
                    <mdb-card-header color="blue darken-3">Informations</mdb-card-header>
                    <mdb-card-body>
                      <mdb-select
                        color="primary"
                        v-model="types"
                        :label="$t('alerts.type')"
                        v-on:getValue="getSelectedValue"
                        :visibleOptions=10
                      />
                      <mdb-input :value="handleStartatPickersValue()" @click.native="$refs.startAtDatePicker.open()" />
                      <mdb-date-picker-2
                        ref="startAtDatePicker"
                        :title="$t('alerts.startat')"
                        disable-input
                        v-model="startAtDatePickerValue"
                        @close="$refs.startAtTimePicker.open()" />
                      <mdb-time-picker
                        ref="startAtTimePicker"
                        :hoursFormat="24"
                        disable-input
                        v-model="startAtTimePickerValue" />

                      <mdb-input :value="handleEndatPickersValue()" @click.native="$refs.endAtDatePicker.open()" />
                      <mdb-date-picker-2
                        ref="endAtDatePicker"
                        :title="$t('alerts.endat')"
                        disable-input
                        v-model="endAtDatePickerValue"
                        @close="$refs.endAtTimePicker.open()" />
                      <mdb-time-picker
                        ref="endAtTimePicker"
                        :hoursFormat="24"
                        disable-input
                        v-model="endAtTimePickerValue" />
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-4">
                <mdb-col>
                  <mdb-card>
                    <mdb-card-header color="blue darken-3">Français</mdb-card-header>
                    <mdb-card-body>
                      <mdb-input :label="$t('alerts.title')" type="text" v-model="alertinfos.title_fr" />
                      <VueEditor :placeholder="$t('alerts.content')" v-model="alertinfos.content_fr" :editorToolbar="customToolbar" />
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-4">
                <mdb-col>
                  <mdb-card>
                    <mdb-card-header color="blue darken-3">English</mdb-card-header>
                    <mdb-card-body>
                      <mdb-input :label="$t('alerts.title')" type="text" v-model="alertinfos.title_en" />
                      <VueEditor :placeholder="$t('alerts.content')" v-model="alertinfos.content_en" :editorToolbar="customToolbar" />
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <mdb-row class="mb-5">
                <mdb-col>
                  <mdb-btn color="elegant" type="button" class="btn-block z-depth-2" v-on:click="savealert()">{{$t('alerts.save')}}</mdb-btn>
                </mdb-col>
              </mdb-row>
            </div>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbAlert,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbDatePicker2,
  mdbTimePicker,
  mdbSpinner,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import { VueEditor } from 'vue2-editor';
import Apicall from '@/libs/Apicall';

export default {
  name: 'userAdd',
  data() {
    return {
      error: null,
      success: null,
      isloading: true,
      alertinfos: null,
      editor_fr: null,
      editor_en: null,
      startAtDatePickerValue: '',
      startAtTimePickerValue: '',
      endAtDatePickerValue: '',
      endAtTimePickerValue: '',
      types: [
        { text: 'ALERT (RED)', value: 'ALERT', selected: false },
        { text: 'INFORMATION (BLUE)', value: 'INFORMATION', selected: false },
        { text: 'WARNING (YELLOW)', value: 'WARNING', selected: false },
      ],
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbAlert,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbDatePicker2,
    mdbTimePicker,
    mdbSpinner,
    VueEditor,
  },
  mounted() {
    this.loadAlert();
  },
  methods: {
    loadAlert() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/alert/details', { ident: this.$route.params.ident }).then((response) => {
        self.isloading = false;
        if (response.status === 'OK') {
          self.alertinfos = response.data.alertinfos;
          for (let i = 0; i < self.types.length; i += 1) {
            if (self.types[i].value === self.alertinfos.type) {
              self.types[i].selected = true;
            }

            if (self.alertinfos.endat) {
              const enddate = new Date(self.alertinfos.endat);
              self.endAtDatePickerValue = self.formatDate(enddate);
              self.endAtTimePickerValue = self.formatTime(enddate);
            }
            const startdate = new Date(self.alertinfos.startat);
            self.startAtDatePickerValue = self.formatDate(startdate);
            self.startAtTimePickerValue = self.formatTime(startdate);
          }
        } else {
          self.error = response.msg;
        }
      });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };
      return thedate.toLocaleDateString('en-CA', options);
    },
    formatTime(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
      return thedate.toLocaleTimeString('en-CA', options);
    },
    savealert() {
      this.alertinfos.startat = `${this.startAtDatePickerValue} ${this.startAtTimePickerValue}`;
      this.alertinfos.endat = `${this.endAtDatePickerValue} ${this.endAtTimePickerValue}`;
      const self = this;
      const api = new Apicall();
      this.error = null;
      api.call('POST', 'api/alert/edit', this.alertinfos).then((response) => {
        if (response.status === 'OK') {
          self.$router.push({ name: 'AlertsList' });
        } else {
          window.scrollTo(0, 0);
          self.error = response.msg;
        }
      });
    },
    getSelectedValue(value) {
      this.alertinfos.type = value;
    },
    handleStartatPickersValue() {
      let output = 'Date / heure de début';
      if (this.startAtDatePickerValue && this.startAtTimePickerValue) output = `${this.startAtDatePickerValue}, ${this.startAtTimePickerValue}`;
      else if (this.startAtDatePickerValue) output = this.startAtDatePickerValue;
      else if (this.startAtTimePickerValue) output = this.startAtTimePickerValue;
      return output;
    },
    handleEndatPickersValue() {
      let output = 'Date / heure de fin';
      if (this.endAtDatePickerValue && this.endAtTimePickerValue) output = `${this.endAtDatePickerValue}, ${this.endAtTimePickerValue}`;
      else if (this.endAtDatePickerValue) output = this.endAtDatePickerValue;
      else if (this.endAtTimePickerValue) output = this.endAtTimePickerValue;
      return output;
    },
  },
};
</script>

<style>
.ql-editor strong{
  font-weight: bold;
}
</style>

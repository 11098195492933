<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>Ajout d'une alerte</h1>
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            <mdb-row class="mb-4">
              <mdb-col>
                <mdb-card>
                  <mdb-card-header color="blue darken-3">Informations</mdb-card-header>
                  <mdb-card-body>
                    <mdb-select
                      color="primary"
                      v-model="types"
                      :label="$t('alerts.type')"
                      v-on:getValue="getSelectedValue"
                      :visibleOptions=10
                    />
                    <mdb-input :value="handleStartatPickersValue()" @click.native="$refs.startAtDatePicker.open()" />
                    <mdb-date-picker-2
                      ref="startAtDatePicker"
                      :title="$t('alerts.startat')"
                      disable-input
                      v-model="startAtDatePickerValue"
                      @close="$refs.startAtTimePicker.open()" />
                    <mdb-time-picker
                      ref="startAtTimePicker"
                      :hoursFormat="24"
                      disable-input
                      v-model="startAtTimePickerValue" />

                    <mdb-input :value="handleEndatPickersValue()" @click.native="$refs.endAtDatePicker.open()" />
                    <mdb-date-picker-2
                      ref="endAtDatePicker"
                      :title="$t('alerts.endat')"
                      disable-input
                      v-model="endAtDatePickerValue"
                      @close="$refs.endAtTimePicker.open()" />
                    <mdb-time-picker
                      ref="endAtTimePicker"
                      :hoursFormat="24"
                      disable-input
                      v-model="endAtTimePickerValue" />
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-4">
              <mdb-col>
                <mdb-card>
                  <mdb-card-header color="blue darken-3">Français</mdb-card-header>
                  <mdb-card-body>
                    <mdb-input :label="$t('alerts.title')" type="text" v-model="alertinfos.title_fr" />
                    <VueEditor :placeholder="$t('alerts.content')" v-model="alertinfos.content_fr" :editorToolbar="customToolbar" />
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-4">
              <mdb-col>
                <mdb-card>
                  <mdb-card-header color="blue darken-3">English</mdb-card-header>
                  <mdb-card-body>
                    <mdb-input :label="$t('alerts.title')" type="text" v-model="alertinfos.title_en" />
                    <VueEditor :placeholder="$t('alerts.content')" v-model="alertinfos.content_en" :editorToolbar="customToolbar" />
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
            <mdb-row class="mb-5">
              <mdb-col>
                <mdb-btn color="elegant" type="button" class="btn-block z-depth-2" v-on:click="savealert()">{{$t('alerts.add')}}</mdb-btn>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbAlert,
  mdbInput,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbDatePicker2,
  mdbTimePicker,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import { VueEditor } from 'vue2-editor';
import Apicall from '@/libs/Apicall';

export default {
  name: 'userAdd',
  data() {
    return {
      error: null,
      success: null,
      alertinfos: {
        start_at: '',
        end_at: '',
        type: '',
        title_fr: '',
        content_fr: null,
        title_en: '',
        content_en: null,
      },
      editor_fr: null,
      editor_en: null,
      startAtDatePickerValue: '',
      startAtTimePickerValue: '',
      endAtDatePickerValue: '',
      endAtTimePickerValue: '',
      types: [
        { text: 'ALERT (RED)', value: 'ALERT', selected: false },
        { text: 'INFORMATION (BLUE)', value: 'INFORMATION', selected: false },
        { text: 'WARNING (YELLOW)', value: 'WARNING', selected: false },
      ],
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbAlert,
    mdbInput,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbDatePicker2,
    mdbTimePicker,
    VueEditor,
  },
  methods: {
    savealert() {
      this.alertinfos.start_at = `${this.startAtDatePickerValue} ${this.startAtTimePickerValue}`;
      this.alertinfos.end_at = `${this.endAtDatePickerValue} ${this.endAtTimePickerValue}`;
      const self = this;
      const api = new Apicall();
      this.error = null;
      api.call('POST', 'api/alert/add', this.alertinfos).then((response) => {
        if (response.status === 'OK') {
          self.$router.push({ name: 'AlertsList' });
        } else {
          window.scrollTo(0, 0);
          self.error = response.msg;
        }
      });
    },
    getSelectedValue(value) {
      this.alertinfos.type = value;
    },
    handleStartatPickersValue() {
      let output = 'Date / heure de début';
      if (this.startAtDatePickerValue && this.startAtTimePickerValue) output = `${this.startAtDatePickerValue}, ${this.startAtTimePickerValue}`;
      else if (this.startAtDatePickerValue) output = this.startAtDatePickerValue;
      else if (this.startAtTimePickerValue) output = this.startAtTimePickerValue;
      return output;
    },
    handleEndatPickersValue() {
      let output = 'Date / heure de fin';
      if (this.endAtDatePickerValue && this.endAtTimePickerValue) output = `${this.endAtDatePickerValue}, ${this.endAtTimePickerValue}`;
      else if (this.endAtDatePickerValue) output = this.endAtDatePickerValue;
      else if (this.endAtTimePickerValue) output = this.endAtTimePickerValue;
      return output;
    },
  },
};
</script>

<style>
  .ql-editor strong{
    font-weight: bold;
  }
</style>

<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <mdb-row class="mb-3">
              <mdb-col md="6">
                <h1>Alertes</h1>
              </mdb-col>
              <mdb-col md="6" class="text-right">
                <mdb-btn color="primary" v-on:click="gotoaddnewalert">Ajouter une alerte</mdb-btn>
              </mdb-col>
            </mdb-row>
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-row>
              <mdb-col md="12">
                <mdb-card class="mb-4">
                  <mdb-card-header color="grey darken-3">Alertes actives</mdb-card-header>
                  <mdb-card-body>
                    <mdb-spinner v-if="isloading" size="lg" />
                    <mdb-alert color="info" v-if="!isloading && !activealerts.length">aucune alerte active</mdb-alert>
                    <mdb-tbl sm>
                      <mdb-tbl-body>
                        <tr v-for="alert in activealerts" v-bind:key="alert.ident" >
                          <td>
                            {{alert.title_fr}} ({{alert.type}})
                            <br />
                            Ajouté par: {{alert.added_by}}
                          </td>
                          <td>
                            du {{formatDate(alert.startat)}} <div v-if="alert.endat">au {{formatDate(alert.endat)}}</div>
                          </td>
                          <td class="text-right">
                            <mdb-btn-group size="sm">
                              <mdb-btn color="grey lighten-2" icon="eye" v-on:click="viewdetails(alert)" />
                              <mdb-btn color="red lighten-2" icon="trash-alt" v-on:click="deletealert(alert)" />
                            </mdb-btn-group>
                          </td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="12">
                <mdb-card class="mb-4">
                  <mdb-card-header color="unique-color">Alertes futures</mdb-card-header>
                  <mdb-card-body>
                    <mdb-spinner v-if="isloading" size="lg" />
                    <mdb-alert color="info" v-if="!isloading && !futuralerts.length">aucune alerte future</mdb-alert>
                    <mdb-tbl sm>
                      <mdb-tbl-body>
                        <tr v-for="alert in futuralerts" v-bind:key="alert.ident" >
                          <td>
                            {{alert.title_fr}} ({{alert.type}})
                            <br />
                            Ajouté par: {{alert.added_by}}
                          </td>
                          <td>
                            du {{formatDate(alert.startat)}} <div v-if="alert.endat">au {{formatDate(alert.endat)}}</div>
                          </td>
                          <td class="text-right">
                            <mdb-btn-group size="sm">
                              <mdb-btn color="grey lighten-2" icon="eye" v-on:click="viewdetails(alert)" />
                              <mdb-btn color="red lighten-2" icon="trash-alt" v-on:click="deletealert(alert)" />
                            </mdb-btn-group>
                          </td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="12">
                <mdb-card>
                  <mdb-card-header color="indigo lighten-1">Alertes expirées</mdb-card-header>
                  <mdb-card-body>
                    <mdb-spinner v-if="isloading" size="lg" />
                    <mdb-alert color="info" v-if="!isloading && !pastalerts.length">aucune alerte expirée</mdb-alert>
                    <mdb-tbl sm>
                      <mdb-tbl-body>
                        <tr v-for="alert in pastalerts" v-bind:key="alert.ident" >
                          <td>
                            {{alert.title_fr}} ({{alert.type}})
                            <br />
                            Ajouté par: {{alert.added_by}}
                          </td>
                          <td>
                            du {{formatDate(alert.startat)}} <div v-if="alert.endat">au {{formatDate(alert.endat)}}</div>
                          </td>
                          <td class="text-right">
                            <mdb-btn-group size="sm">
                              <mdb-btn color="grey lighten-2" icon="eye" v-on:click="viewdetails(alert)" />
                              <mdb-btn color="red lighten-2" icon="trash-alt" v-on:click="deletealert(alert)" />
                            </mdb-btn-group>
                          </td>
                        </tr>
                      </mdb-tbl-body>
                    </mdb-tbl>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="confirmdelete" @close="confirmdelete = false" size="md" class="text-center" danger>
      <mdb-modal-header center :close="false">
        <p class="heading">{{$t('users.are_you_sure')}}</p>
      </mdb-modal-header>
      <mdb-modal-body>
        Vous êtes sur le point de supprimer l'alerte <br /><br /><strong>{{alerttodelete?alerttodelete.title_fr:''}}</strong><br />
        <mdb-icon icon="times" size="4x" class="animated rotateIn"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" v-on:click="executedelete()">{{$t('users.yes')}}</mdb-btn>
        <mdb-btn color="danger" @click="confirmdelete = false">{{$t('users.no')}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbBtnGroup,
  mdbContainer,
  mdbTbl,
  mdbTblBody,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbIcon,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbCardHeader,
  mdbSpinner,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'documentationCenter',
  data() {
    return {
      alerts: [],
      isloading: true,
      confirmdelete: false,
      alerttodelete: null,
      error: null,
    };
  },
  computed: {
    activealerts() {
      const alerttoreturn = [];
      this.alerts.forEach((alert) => {
        if (new Date(alert.startat).getTime() <= new Date().getTime()) {
          if (!alert.endat) {
            alerttoreturn.push(alert);
          } else if (new Date(alert.endat).getTime() >= new Date().getTime()) {
            alerttoreturn.push(alert);
          }
        }
      });
      return alerttoreturn;
    },
    pastalerts() {
      const alerttoreturn = [];
      this.alerts.forEach((alert) => {
        if (new Date(alert.endat).getTime() <= new Date().getTime() && alert.endat) {
          alerttoreturn.push(alert);
        }
      });
      return alerttoreturn;
    },
    futuralerts() {
      const alerttoreturn = [];
      this.alerts.forEach((alert) => {
        if (new Date(alert.startat).getTime() > new Date().getTime()) {
          alerttoreturn.push(alert);
        }
      });
      return alerttoreturn;
    },
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbSpinner,
    mdbTbl,
    mdbTblBody,
    mdbAlert,
    mdbBtnGroup,
    mdbBtn,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbIcon,
  },
  mounted() {
    this.loadAllAlerts();
  },
  methods: {
    loadAllAlerts() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/alert/list').then((response) => {
        self.isloading = false;
        if (response.status === 'OK') {
          self.alerts = response.data;
        } else {
          self.error = response.msg;
        }
      });
    },
    deletealert(alert) {
      this.confirmdelete = true;
      this.alerttodelete = alert;
    },
    executedelete() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/alert/delete', { ident: this.alerttodelete.ident }).then((response) => {
        if (response.status !== 'OK') {
          self.error = response.msg;
        }
        self.loadAllAlerts();
        self.alerttodelete = null;
        self.confirmdelete = null;
      });
    },
    viewdetails(alert) {
      this.$router.push({ name: 'ViewAlert', params: { ident: alert.ident } });
    },
    gotoaddnewalert() {
      this.$router.push({ name: 'AddAlert' });
    },
    formatDate(_thedate) {
      const thedate = new Date(_thedate);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
  },
};
</script>
